
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import logo from "./logo.svg";

import './style.css'
import Process from './views/process'
import Home from './views/home'
import NotFound from './views/not-found'

// import from './'
import './style.css';

// import './App.css';

// function App() {
//   return (
//     <div className="App">
    
//       <Home/>
//     </div>


//   );
// }


const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Process/>} exact path="/process" />
        <Route element={<Home/>} exact path="/" />
        <Route element={<NotFound/>} path="**" />
      </Routes>
    </Router>
  )
}

export default App;
