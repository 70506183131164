import React from 'react'

import { Helmet } from 'react-helmet'

import './process.css'

const Process = (props) => {
  return (
    <div className="process-container">
      <Helmet>
        <title>Process - Fleejo</title>
        <meta property="og:title" content="Process - Happy Old Rat" />
      </Helmet>
    </div>
  )
}

export default Process
