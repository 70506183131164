import React from 'react'

// import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'



import './home.css'

import { useEffect } from 'react';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

//eqi5JSkEDXE
const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive" style={{width:"100%", height:"100%"}}>
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${embedId}`}
      // frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

//8lw79bbg3j
function WistiaPlayer({ videoId, wrapper }) {
  useEffect(() => {
    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    const div = document.createElement("div");
    div.innerHTML = `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_${videoId} seo=false videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/${videoId}/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`;
    const container = document.getElementById(wrapper);
    container.appendChild(script1);
    container.appendChild(script2);
    container.appendChild(div);

    return () => {
      // Cleanup code
      container.innerHTML = "";
    };
  }, []);

  return <div id={`${wrapper}`} style={{height:"100%", width:"100%"}}></div>;
}

const Home = (props) => {
  useScript("https://assets.calendly.com/assets/external/widget.js");

  return (
    <div className="home-container">
      <Helmet>
        <title>Fleejo | Home Page</title>
        <meta property="og:title" content="Fleejo | Home Page" />

      </Helmet>
      <div className="home-container01">
        <img alt="image" src="/fleejofinal-200h.png" className="home-image" />
        <h5 className="home-text">Fleejo</h5>
        <a
          href="https://gamma.app/docs/Get-software-built-at-110th-the-cost-of-traditional-dev-and-5-10x-1hm3q3dh36fkjpj?mode=doc"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link"
        >
          <h5 className="home-text001">Thesis</h5>
        </a>
      </div>
      <div className="home-container02">
        <h1 className="home-heading">
          Get software built at 1/10th the cost of traditional dev, and 5-10x
          faster via nocode
        </h1>
      </div>
      <div className="home-container03">
        <div className="home-container04">
          <WistiaPlayer videoId='45c8s50nmn' wrapper="wistia-player-container-1"></WistiaPlayer>
        </div>
        <a href="#callsection" className="home-link1">
          <div className="home-container05">
            <span className="home-text002">Get Started</span>
          </div>
        </a>
        <h2 className="home-text003">
          <span>Most of the concerns are answered in the video above.</span>
          <br></br>
          <span>
            If you want to go at your own pace, click &quot;Thesis&quot; to open
            the document.
          </span>
          <br></br>
        </h2>
      </div>
      <div className="home-container06">
        <div className="home-container07">
          <h2 className="home-text008">What is nocode?</h2>
          <span className="home-text009">
            <span>
              NoCode / LowCode is a modern method of software dev which requires
              zero to minimal amounts of code to build the software thanks to
              interactive editors. Which means you can make good looking
              software fast.
            </span>
            <br></br>
            <br></br>
            <span>
              Traditional software dev is when you build software via code, and
              which ever way you build software using code, it is just so
              expensive.
            </span>
            <br></br>
            <br></br>
            <span>
              Wether you hire an inhouse team where you would have talent
              acquisition cost and it would take so long to build. Similar is
              the case with an agency.
            </span>
            <br></br>
            <br></br>
            <span>
              Even if you want to make software yourself, the preferred path is
              nocode, but you still need to have knowledge of databases,
              workflows, design, element states, authentication, and just hours
              of practice, but this definitely is one of the shortest paths to
              getting a software. It just is the best way to build software for
              many of the use cases.
            </span>
          </span>
        </div>
        <div className="home-container08">
          <h2 className="home-text020">
            Does software made with nocode even look good?
          </h2>
          <img
            alt="image"
            src="/softwarescreenshot_1-1500w.png"
            className="home-image1"
          />
          <img
            alt="image"
            src="/softwarescreenshot_2-1500w.png"
            className="home-image2"
          />
        </div>
        <div className="home-container09">
          <h2 className="home-text021">
            Which platform is used to make the software above?
          </h2>
          <span className="home-text022">
            <span>
              Now there are a lot of nocode tools in the market and the tool
              preferred by me is Bubble io. It is the most broad(in terms of
              features) and is a mature tool in the market.
            </span>
            <br></br>
            <br></br>
            <span>It has 2m+ users</span>
            <br></br>
            <br></br>
            <span>Has a 50k+ members in the community</span>
            <br></br>
            <br></br>
            <span>It was founded in 2012.</span>
            <br></br>
            <br></br>
            <span>
              Basically it is a reliable platform. And the software above was
              made in matter of days.
            </span>
          </span>
        </div>
        <div className="home-container10">
          <h2 className="home-text036">
            How does nocode helps to increase the speed of dev? :
          </h2>
          <span className="home-text037">
            <span>
              In the traditional dev where you have to write the backend, and
              then the frontend and you are jumping back and forth, and you need
              one more feature, so you have to make so many changes, and
              everything has an unnecessary drag to it.
            </span>
            <br></br>
            <br></br>
            <span>
              Making software in bubble io is actually very similar to coding,
              you have a database, you configure the database according to your
              needs and you need a Ui component to show the data.
            </span>
            <br></br>
            <br></br>
            <span>
              But the difference is that you don&apos;t have to manually write
              the api calls or manually make sure the database environment is
              correctly set up and you are also not making Ui by typing code.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              And when you are writing code you naturally get more errors. When
              you are bubble, you don&apos;t even have to write the api calls
              neither worry much about the database, which is very impressive.
            </span>
            <br></br>
            <br></br>
            <span>
              Pretty much all of the webapps require User Login and Signup
              system. This feature is also simply baked into every single of the
              bubble app.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              When using nocode you can simply focus more on the actual User
              experience, the part that really matters to the user, essentially
              making the software look pretty. And pretty much nobody wants to
              use software that took so much time to build but has bad Ui.
            </span>
          </span>
          <div className="home-container11">
          <h1 className="home-text054">
            <span>Timeline of work</span>
            <br></br>
          </h1>
          <div className="home-container12">
            <h1 className="home-text057">Call</h1>
            <span className="home-text058">
              <span>● Get to list down all the required features.</span>
              <br></br>
              <span>
                {' '}
                ● Listing down all the integrations.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ● Collecting references if any.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ●) Agreeing on color theme
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>● Sprints mapped out on timeline together.</span>
            </span>
          </div>
          <div className="home-container13">
            <h1 className="home-text068">Onboarding</h1>
            <span className="home-text069">
              <span>● Slack Channel for communication.</span>
              <br></br>
              <span>
                {' '}
                ● Access to Kanban board for small revisions
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ● Access to timeline for clear visualization of sprints.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-container14">
            <h1 className="home-text076">
              <span>Data Config</span>
              <br></br>
            </h1>
            <span className="home-text079">
              <span>● Designing the database according to features.</span>
              <br></br>
              <span>
                {' '}
                ● Integrations set up if any.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-container15">
            <h1 className="home-text085">Call</h1>
            <span className="home-text086">
              <span>● Components choosed</span>
              <br></br>
              <span>
                {' '}
                ● Color Theme finalized
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ● Icons finalized
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ●) Some components made in Figma
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>● All this will be reviewed by you</span>
            </span>
          </div>
          <div className="home-container16">
            <h1 className="home-text096">First Page milestone</h1>
            <span className="home-text097">
              <span>
                ● This first page that will be made would be decided on the
                call.
              </span>
              <br></br>
              <span>
                {' '}
                ● Will be reviewed by you, and iterated until you are satisfied.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                ● Then the rest of the sprints will be completed rapidly.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-container17">
            <h1 className="home-text104">Sprints</h1>
            <span className="home-text105">
              <span>● Raw execution of what has been planned.</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-container18">
            <h1 className="home-text109">Feedback loop</h1>
            <span className="home-text110">
              <span>● You will be informed at the end of every sprint</span>
              <br></br>
              <span>
                {' '}
                ●
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Feedback will be taken and revisions will be made.</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </div>
        </div>
        </div>
        
      </div>
      <div id="callsection" className="home-costsection">
        <div className="home-container19">
          <h2 className="home-text117">
            What is Guaranteed in the whole process? 
          </h2>
          <span className="home-text118">
            The whole process will be operated on a satisfaction guarantee,
            meaning unlimited revisions can be made until you are satisfied, the
            only thing would happen is timeline of work would extend. + it is a
            subscription based model, you can cancel anytime.
          </span>
        </div>
        <img
          alt="image"
          src="/pricingmobile-1500w.png"
          className="home-image3"
        />
        <img
          src="/pricingdesktop-1100w.png"
          alt="image"
          className="home-image4"
        />
        <div className="home-calendleycontainer">
          <div className="home-container20">
            <div className="home-container21">
              <div className="home-container22">
                <div className="calendly-inline-widget"data-url="https://calendly.com/aligdristor/30min"style={{ minWidth: '100%', height: '100%' }}> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default Home
